import React from 'react';
import './NavBar.scss';
import logo from '../assets/laps-icon.png';
import TwitterIcon from '@mui/icons-material/Twitter';
import DiscordIcon from '../assets/discord-24.png';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';


interface Props {

}



export default function NavBar(props: Props): JSX.Element {

  return (
    <Navbar className="navbar-container" collapseOnSelect expand="lg" variant="dark">
      <div className="custom-container">
      <Navbar.Brand href="#home" style={{position: "relative"}}><img className="laps-icon-img" alt="laps" src={logo}/><label className="beta-txt">beta</label></Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="me-auto"></Nav>
        <Nav>
        <Nav.Link style={{color:'white'}} target="_blank" rel="noopener noreferrer"  href="https://twitter.com/Laps_app">Twitter</Nav.Link>
          <Nav.Link style={{color:'white'}} target="_blank" rel="noopener noreferrer"  href="https://discord.com/invite/WkzGEgZjUc">Discord</Nav.Link>
          <Nav.Link style={{color:'white'}} target="_blank" rel="noopener noreferrer" href="https://medium.com/@laps_app">Docs</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      </div>
    </Navbar>
    // <div className="navbar-container d-flex justify-content-between align-items-center">
    //   <button className="laps-btn"><img className="laps-icon-img" alt="laps" src={logo}/></button>
    //   <div>
    //     <a className="nav-txt" href="https://twitter.com/Laps_app">Release Notes</a>
    //     <a className="nav-txt" href="https://twitter.com/Laps_app">Twitter</a>
    //     <a className="nav-txt-last" href="https://discord.com/invite/WkzGEgZjUc">Discord</a>
    //     {/* <a style={{marginRight: "12px", color: "white"}} href="https://twitter.com/Laps_app"><TwitterIcon /></a>
    //     <a href="https://twitter.com/Laps_app"><img alt="discord" src={DiscordIcon}/></a> */}
    //   </div>
    // </div>
  );
}
