import React from 'react';
import logo from './logo.svg';
import './App.scss';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

function App() {
  const [showNavBar, setShowNavBar] = React.useState(true);
  return (
    <div>
      {showNavBar && <NavBar/>}
      <Router>
        <div className="app-container custom-container">
          <Switch>
            <Route path="/discord" component={() => {
              setShowNavBar(false);
               window.location.href = 'https://discord.com/invite/WkzGEgZjUc'; 
               return null;
            }}>
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
      </Router>
      </div>
  );
}

export default App;
